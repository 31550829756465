import {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import JsonHandler from "../service/JsonHandler";
// import GaugeComponent from "react-gauge-component";
import ErrorRoute from "../components/route/ErrorRoute";
import { Oval } from "react-loader-spinner";
import GaugeChart from "./GaugeChart";
import randomColor from "randomcolor";
import "./templates.css";

const Gauges = () => {
    let {machine_id, topic_id} = useParams();
    const [currentTopic, setCurrentTopic] = useState({});
    const [message, setMessage] = useState(null);
    const [gaugeStatus, setGaugeStatus] = useState(null);
    const [options, setOptions] = useState(null);
    const location = useLocation();
    const current_display = location.pathname.split("/")[2];
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);
    const [ticks, setTicks] = useState(null);

    const index =topic_id;


    useEffect(() => {
        if(currentTopic.topic_params){
        const renderOptionsGauge = async () => {
                const tempKeys = [];
                const tempVal = [];
                Object.entries(currentTopic.topic_params).forEach(([key, value]) => {
                    tempKeys.push(key);
                    tempVal.push(value);
                });
                
                const result = {
                    content: tempKeys,
                    val : tempVal
                };
                setOptions(result);
                return true;
                
            }
            
            renderOptionsGauge();
        }
    }, [currentTopic]);

    useEffect(() => {
      if(message && options){
        const gaugeTextValue = async () => {
            let gaugeName = null;
            let messContent = null;
            //reminder options.content is the state and options vals the interval
            options.val.forEach((elm, index)=> {
                const last = options[options.val.length - 1];
                gaugeName = options.content[index];
                //if not max
                if (gaugeName !== "max") {
                    //set mess content to the right interval
                    if (message >= elm && message <= options.val[index+1] ) {
                        messContent = options.content[index];
                    }
                //if max and value in the last interval, set mess content
                } else if (last > message && options.val[index - 1] <= message ) {
                    messContent = options.content[index -1];
                //else if value is too much and not 0 ( to prevent errors )
                } else if(last <= message || message<0){
                    messContent = options.content[index];
                }
            });
            setGaugeStatus(messContent);
        }

        gaugeTextValue();
      }
    }, [message, options, setGaugeStatus]);
    
    
    useEffect(() => {
        const fetchTopic = async () => {
            const res = await JsonHandler(`message/${machine_id}/${index}`, "POST");
            if(res.success){
                setMessage(res.message);
                setCurrentTopic(res.topic);
                setBgData(res.background);
                setLoading(false);
                setDataEmpty(false);
            }
        }
        
        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }

        const intervalId = setInterval(fetchTopic, 3000);
        const intervalDatas = setInterval(dataLoaded, 10000);
        return () => clearInterval(intervalId, intervalDatas);

    }, [topic_id, setMessage, setCurrentTopic, machine_id, index, setLoading, setDataEmpty, message, setBgData]);    


    const ticksValues = (ticks) => {
        const tempVals = [];
        if (options && options.val) {
            options.val.forEach(elm => {
                const greenRange = Math.floor(options.val.length / 3);
                const yellowOrangeRange = Math.floor((options.val.length / 3) * 2);
                let color;
                if (index < greenRange) {
                    color = randomColor({hue: 'green', luminosity: 'light'})
                } else if (index < yellowOrangeRange) {
                    color = randomColor({hue: 'orange'})
                } else if(index > yellowOrangeRange) {
                    color = randomColor({hue: 'red', luminosity: 'light'})
                }
                tempVals.push({ value: elm, color: color });
            });
        }
        if (!ticks) {
            setTicks(tempVals);
        };
        return ticks;
    }
    

    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

     if (loading) {
        return(
            <section className="display__container tile__background">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }

    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }
    return (
        <>
            {!rightDisplay ? (
                <ErrorRoute />
            ) : (
                <section 
                className="display__container tile__background"
                style={displayTileStyle(bgData, currentTopic.topic_opacity)}
                >
                {currentTopic && options && (
                <>
                    <h2 className="display__title">{currentTopic.topic_title}</h2>
                    {message !== undefined && (
                        <>
                            {message <= 0 ? (
                                <GaugeChart gaugeVal={message} ticksVal={ticksValues(ticks)} state="min" ></GaugeChart>
                            ) : (
                                <>
                                {message === options.content[options.content.length-1] && (
                                    <GaugeChart gaugeVal={message} ticksVal={ticksValues(ticks)} state="max" ></GaugeChart>
                                )}
                                </>
                            )}

                            {message !== options.content[options.content.length-1] && message !== 0 && message > 0 && (
                                    <GaugeChart gaugeVal={message} ticksVal={ticksValues(ticks)} state={gaugeStatus} ></GaugeChart>
                            )}
                        </>

                    )}
                </>
                )}
        </section>
            )}
            </>
        );};

export default Gauges;