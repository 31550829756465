import React from 'react';
import ReactECharts from 'echarts-for-react';

const GaugeChart = ( {gaugeVal, ticksVal, state} ) => {

    const renderColors = () => {
        const maxValue = ticksVal[ticksVal.length - 1].value;
        const percentages = ticksVal.map((tick) => {
            return [ tick ? (tick.value / maxValue) : 0, tick.color];
        });
        
        return percentages;
    }

  const option =
    {series: [
      {
        type: 'gauge',
        min: ticksVal[0].value,
        max: ticksVal[ticksVal.length-1].value, 
        axisLine: {
          lineStyle: {
            width: 30,
            color: renderColors()
          }
        },
        pointer: {
          itemStyle: {
            color: '#345243'
          }
        },
        axisTick: {
          distance: -30,
          length: 8,
          lineStyle: {
            color: '#fff',
            width: 2
          }
        },
        splitLine: {
          distance: -30,
          length: 22,
          lineStyle: {
            color: '#fff',
            width: 4
          }
        },
        axisLabel: {
          color: 'inherit',
          distance: 45,
          fontSize: "0.8rem"
        },
        detail: {
          valueAnimation: true,
          formatter: '{value}',
          color: "#fff",
          fontSize: "1rem"
        },
        data: [
          {
            value: gaugeVal || 0,
            name:  `État : ${state}`,
            decal: {
                color: '#fff',
            }
          }
        ]
      }
    ]};

  return <ReactECharts option={option} style={{ height: '400px' }} />;
};

export default GaugeChart;
