import "./home.css";
import {useState, useRef} from "react";
import useFileContext from "../../hooks/useFileContext";
import {useNavigate} from "react-router-dom";

function Home() {
    const [invalidSheet, setInvalidSheet] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [urlVal, setUrlVal] = useState("");
    const urlRef = useRef(null); 
    const fileRef = useRef(null); 
    const navigate = useNavigate();
    const [file, setFile] = useState(false);
    const {fetchCsvFileFromFile, initUrl, fetchCsvFileFromUrl} = useFileContext();

    const handleFileChange = (e) => {
        if (e.target && e.target.files.length===1) {
            setCsvFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
            setFile(true);
            setInvalidSheet(false);
            setUrlVal("");
        }else{
            setCsvFile(null);
            setFileName(null);
            setFile(false);
        }
    };

    const handleURLChange = (e) => {
        e.target.value ? setUrlVal(e.target.value): setUrlVal(null);
        setFile(false);
        fileRef.current.value = "";
        setFileName(null);
        setInvalidSheet(false);
    };

    const handleFormSubmit = async (ev) => {
        ev.preventDefault();
        if (!urlVal && !csvFile) {
            setInvalidSheet(true);
            return;
        }
        const formData = new FormData();
        let res = null;
        if (file === true ) {
            formData.append("csvFile", csvFile);
            res = await fetchCsvFileFromFile(formData);
        } else {
            formData.append("urlVal", urlVal);
            res = await fetchCsvFileFromUrl(urlVal);
        }
        if (res) {
            setInvalidSheet(false);
            initUrl();
            navigate("/inventory");
        } else {
            setInvalidSheet(true);
        }
    };

    return (
        <main className="f-center home__container">
            <div className="f-center home">
                <img
                    src="/assets/img/smartblueding_logo.jpg"
                    alt="logo"
                    className="home__logo"
                />
                <div className={invalidSheet ? ("tile homeTile homeTile__invalid") : ("tile homeTile")}>
                    <h2 className="homeTile__title">Import Spreadsheet</h2>
                    {invalidSheet ? (
                        <span className="invalidSheet">
                            Url / file path incorrect, please enter an existing
                            url / file path.
                        </span>
                    ) : (
                        <span className="invalidSheet"></span>
                    )}
                    <form
                        action=""
                        method=""
                        encType="multipart/form-data"
                        className="f-center tile__form"
                        onSubmit={handleFormSubmit}
                    >
                        <div className="tile__formItem">
                            {invalidSheet ? (
                                <img
                                    src="assets/img/wrong.png"
                                    alt="Mauvais lien ou url"
                                    className="invalidSheet__img"
                                />
                            ) : (
                                <span className="invalidSheet__img"></span>
                            ) }
                            <input
                                className="tile__input"
                                type="text"
                                name="csvUrl"
                                placeholder="Enter url / file path ..."
                                value={urlVal}
                                ref={urlRef}
                                onChange={handleURLChange}
                            />
                            <div className="upload">
                                <label
                                    className="upload__label"
                                    htmlFor="file-input"
                                >
                                    <img
                                        className="upload__img"
                                        src="assets/img/upload.png"
                                        alt="Uploader un fichier csv"
                                        htmlFor="csvFile"
                                    />
                                </label>
                                <input
                                    className="upload__input"
                                    id="file-input"
                                    type="file"
                                    name="csvFile"
                                    accept=".csv"
                                    ref={fileRef}
                                    onChange={(e)=>{handleFileChange(e)}}
                                />
                                {
                                    fileName ? (
                                        <span className="file-input__mess">{fileName}</span>
                                        ) : (
                                        <span className="file-input__mess">Aucun fichier sélectionné</span>
                                        )
                                    }
                            </div>
                        </div>
                        <button type="submit" className="main__btn">
                            Generate
                        </button>
                    </form>
                </div>
            </div>
        </main>
    );
}

export default Home;
