const JsonHandler = async (endpoint, method, data) => {
    const fetchData = async () => {
        const options = {
            headers: {"Content-Type": "application/json"},
            method: method,
            credentials: "include",
            body: data,
        };
        try {
            const response = await fetch(
                `https://webdemo.smart-blueding.fr/${endpoint}`,
                options
            );
            if (!response.ok) {
                throw new Error("Request failed");
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    };

    return await fetchData();
};

export default JsonHandler;
