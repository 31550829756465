import "./inventory.css";
import Machine from "./Machine";
import {useEffect} from "react";
import useFileContext from "../../hooks/useFileContext";
import {useNavigate} from "react-router-dom";
import RequestHandler from "../../service/RequestHandler";

const Inventory = () => {
    const {fileData, initData, urlForIframe} = useFileContext();
    const navigate = useNavigate();

    useEffect(() => {
        const validFileData = async () => {
            const tempData = await fileData;
            if (!tempData) {
                navigate("/");
            }
        };

        validFileData();
    }, [fileData, navigate, urlForIframe]);

    const renderMachines = () => {
        if (fileData) {
            return fileData.map((machine, index) => {
                return <Machine machine={machine} index={index} key={index}></Machine>;
            });
        }
        return null;
    };

    const goBack = () => {
        initData();
        RequestHandler("logout", "POST");
        navigate("/");
    };

    return (
        <div className="inventory f-center">
            <div className="tile inventoryTile">
                {fileData && <ul className="list">{renderMachines()}</ul>}
                <button
                    className="main__btn"
                    onClick={(e) => {
                        goBack();
                    }}
                >
                    Home page
                </button>
            </div>
            <iframe className="iframe" title="test" src={urlForIframe}></iframe>
        </div>
    );
};
export default Inventory;
