const RequestHandler = async (endpoint, method, formData) => {
    const fetchData = async () => {
        const options = {
            method: method,
            credentials: "include",
        };
        if (formData) options.body = formData;
        try {
            const response = await fetch(
                `https://webdemo.smart-blueding.fr/${endpoint}`,
                options
            );
            if (!response.ok) {
                throw new Error("Request failed");
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    };

    return await fetchData();
};

export default RequestHandler;
