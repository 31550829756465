import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';

const Chart = ({realData}) => {
    
    const [legendData, setLegendData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [seriesDatas, setSeriesDatas] = useState([]);

    //TODO      needs data to be : 
    //TODO      name = ['25/04/2024 17:44:53', '25/04/2024 17:44:53','25/04/2024 17:44:53', '25/04/2024 17:44:53']
    //TODO      Line1 = ['0.5', '0.48', '15', '12']
    //TODO      Curve 1 = ['0.5', '0.48', '15', '12']
    //TODO      Air transformation = ['0.5', '0.48', '15', '12']
    //TODO → revoir toute la logique d'organisation des données pour leur traitement 
    //TODO → data needs to have, in order of different names, a val ( so the past logic is actually useful ) 
    
    useEffect(() => {
      //? legends never dieee ( ˘˘з)♬♪ → reorganize datas to be used in echarts
      const manageDatas = () => {
          const tempLegends = [];
          const tempXaxis = [];
          const tempSeriesDatas = [];
  
          realData.forEach((point) => {
              const xAxisValue = point.name; // Assuming the name field contains the x-axis values
              if (!tempXaxis.includes(xAxisValue)) {
                  tempXaxis.push(xAxisValue);
              }
  
              Object.entries(point).forEach(([keyName, val]) => {
                  if (keyName !== "name") {
                      const existingSeries = tempSeriesDatas.find((item) => item.name === keyName);
                      if (!existingSeries) {
                          // If series doesn't exist, create it
                          tempSeriesDatas.push({
                              name: keyName,
                              type: 'line',
                              areaStyle: true,
                              smooth: true,
                              data: [],
                          });
                          tempLegends.push(keyName);
                      }
  
                      const seriesIndex = tempSeriesDatas.findIndex((item) => item.name === keyName);
                      const seriesData = tempSeriesDatas[seriesIndex].data;
  
                      // Ensure the correct position for the data point based on x-axis value
                      const xAxisIndex = tempXaxis.indexOf(xAxisValue);
                      while (seriesData.length < xAxisIndex) {
                          seriesData.push("-");
                      }
  
                      // Preserve zero values, replace empty values with "-"
                      seriesData.push(val === "" ? "-" : val);
                  }
              });
          });
  
          setSeriesDatas(tempSeriesDatas);
          setLegendData(tempLegends);
          setXAxisData(tempXaxis);
      };
  
      manageDatas();
  }, [realData]);  

    const option = {
        dataZoom: [
        {
            type: 'slider',
            xAxisIndex: 0,
            textStyle: {
              color: "#fff",
            },
            selectedDataBackground: {
              moveHandleStyle: {
                  color: "#fff", 
                  shadowColor: "#fff",
              }
          }
          },
          { type: "inside",
            xAxisIndex: 0,
            start: 30,
            end: 70,
         }
        ],
        tooltip : {
          trigger: 'axis'
        },
        legend: {
          data: legendData,
          textStyle: {
            color: "#fff",
            fontSize: 16
          }
        },
        grid: {
          left: '3%',
          right: "8%",
          bottom: '10%',
          containLabel: true
        },
        xAxis : [
          {
            type : 'category',
            boundaryGap : false,
            data : xAxisData,
            axisLine: {
              lineStyle: {
                color: "#fff",
              },
            }
          }
        ],
        yAxis : [
          {
            type : 'value'
          }
        ],
        series : seriesDatas,
        axisLabel: {
          color: "#fff",
          textBorderColor: "#fff",
          textBorderWidth: "#fff"
        }
    };

    if(option ){
      return (
        <ReactECharts
        option={option}
        style={{ height: 700 }}
        />
      );
    }
}

export default Chart;
