import { Link } from "react-router-dom";

const ErrorRoute = ()=> {
    return(
        <div className="display__container f-center">
            <h1 className="display__title">Erreur 404</h1>
            <p>Désolé, une erreur est survenue.</p>
            <Link to={'/'}>
            <span className="main__btn">Retourner à la page d'accueil</span>
            </Link>
        </div>
    )
}

export default ErrorRoute;