import {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import JsonHandler from "../service/JsonHandler";
import AudioPlayer from "react-modern-audio-player";
import ErrorRoute from "../components/route/ErrorRoute";
import { Oval } from "react-loader-spinner";
import "./templates.css";

const Sounds = () => {
    let {machine_id, topic_id} = useParams();
    const location = useLocation();
    const current_display = location.pathname.split("/")[2];
    const [currentTopic, setCurrentTopic] = useState({});
    const [message, setMessage] = useState(null);
    const [playList, setPlayList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);
    const index = topic_id;
    
    useEffect(() => {
        const fetchTopic = async () => {
            const res = await JsonHandler(`message/${machine_id}/${index}`, "POST");
            if(res.success){
                setMessage(JSON.parse(res.message));
                setCurrentTopic(res.topic);
                setBgData(res.background);
            }
            setLoading(false);
        }

        if (!message) {
            fetchTopic();
        }

        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }
        const intervalId = setInterval(fetchTopic, 3000);
        const intervalDatas = setInterval(dataLoaded, 20000);
        return () => clearInterval(intervalId, intervalDatas);
    }, [machine_id, setCurrentTopic, message, setLoading, index, setDataEmpty,setBgData]);    
    
    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

    useEffect(() => {
        if (!playList) {
            const createPlayList = async () => {
                if(message && rightDisplay){
                    const newPlayList = message.urls.map((url, index) => (
                    {
                        //complex line but this way it fits in one line     
                        name: decodeURIComponent(url.split('/')[url.split('/').length - 1]),
                        writer: "",
                        img: "",
                        src: url,
                        id: index + 1
                    }));
                    setPlayList(newPlayList);
                }
            }
            createPlayList();
        }
    }, [message, setPlayList, playList, rightDisplay]);

    if (loading) {
        return(
            <section className="tile__background display__container">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }
    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }

    return (
        <>
            {!rightDisplay ? (
                <ErrorRoute />
            ) : (
                <section 
                className="display__container tile__background"
                style={displayTileStyle(bgData, currentTopic.topic_opacity)}
                >
                    {currentTopic && (
                        <>
                        <h2 className="display__title">{currentTopic.topic_title}</h2>
                        {message && playList && (
                            <AudioPlayer
                            placement={{
                                interface: {
                                templateArea: "top"
                                },
                                playList: "bottom",
                                volumeSlider: "top"
                            }}
                            playList={playList}
                            activeUI={{ all: true }}
                            rootContainerProps={{
                                width : "100%"
                            }}
                            />
                        )}
                        </>
                    )}
                </section>
            )}
        </>
    );
};

export default Sounds;