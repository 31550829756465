import Topics from "./Topics";

const Machine = ({machine, index}) => {
    return (
        <li className="listItem">
            <p className="listItem__title">{machine.unique_id}</p>
            <Topics machine={machine} />
        </li>
    );
};
export default Machine;
