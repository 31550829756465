import React from "react";
import {FileProvider} from "./context/fileContext";
import Home from "./components/home/Home";
import Inventory from "./components/inventory/Inventory";
import ErrorRoute from "./components/route/ErrorRoute";
import {
    createBrowserRouter,
    RouterProvider,
    Routes,
    Route,
} from "react-router-dom";

import Curves from "./templates/Curves";
import Gauges from "./templates/Gauges";
import HistoryCurves from "./templates/HistoryCurves";
import Indicators from "./templates/Indicators";
import Links from "./templates/Links";
import Media from "./templates/Media";
import PieChart from "./templates/PieChart";
import Sounds from "./templates/Sounds";
import Texts from "./templates/Texts";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorRoute />,
    },
    {
        path: "/inventory",
        element: <Inventory />,
        errorElement: <ErrorRoute />,
    },
    {
        path: "/render/sounds/:machine_id/:topic_id",
        element: <Sounds />,
        errorElement: <ErrorRoute />,
    },
    {
        path: "/render/links/:machine_id/:topic_id",
        element: <Links />,
        errorElement: <ErrorRoute />,

    },
    {
        path: "/render/texts/:machine_id/:topic_id",
        element: <Texts />,
        errorElement: <ErrorRoute />,

    },
    {
        path: "/render/indicators/:machine_id/:topic_id",
        element: <Indicators />,
        errorElement: <ErrorRoute />,

    },
    {
        path: "/render/media/:machine_id/:topic_id",
        element: <Media />,
        errorElement: <ErrorRoute />,

    },
    {
        path: "/render/gauges/:machine_id/:topic_id",
        element: <Gauges />,
        errorElement: <ErrorRoute />,

    },
    {
        path: "/render/piechart/:machine_id/:topic_id",
        element: <PieChart/>,
        errorElement: <ErrorRoute />,

    },
    {
        path: "/render/historycurves/:machine_id/:topic_id",
        element: <HistoryCurves/>,
        errorElement: <ErrorRoute />,

    },
    {
        path: "/render/curves/:machine_id/:topic_id",
        element: <Curves />,
        errorElement: <ErrorRoute />,

    },
]);

function App() {
    return (
        <FileProvider>
            <RouterProvider router={router}>
                <Routes>
                    <Route
                        path="/render/curves/:machine_id/:topic_id"
                        element={<Curves />}
                    />
                    <Route
                        path="/render/indicators/:machine_id/:topic_id"
                        element={<Indicators />}
                    />
                    <Route
                        path="/render/text/:machine_id/:topic_id"
                        element={<Texts />}
                    />
                    <Route
                        path="/render/log/:machine_id/:topic_id"
                        element={<Links />}
                    />
                    <Route
                        path="/render/piechart/:machine_id/:topic_id"
                        element={<PieChart />}
                    />
                    <Route
                        path="/render/gauges/:machine_id/:topic_id"
                        element={<Gauges />}
                    />
                    <Route
                        path="/render/media/:machine_id/:topic_id"
                        element={<Media />}
                    />
                    <Route
                        path="/render/links/:machine_id/:topic_id"
                        element={<Links />}
                    />
                    <Route
                        path="/render/historycurves/:machine_id/:topic_id"
                        element={<HistoryCurves />}
                    />
                    <Route
                        path="/render/sounds/:machine_id/:topic_id"
                        element={<Sounds />}
                    />
                </Routes>
            </RouterProvider>
        </FileProvider>
    );
}

export default App;
