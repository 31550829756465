import {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import JsonHandler from "../service/JsonHandler";
import { Oval } from "react-loader-spinner";
import Chart from "./Chart";
import ErrorRoute from "../components/route/ErrorRoute";

const HistoryCurves = () => {
    const {machine_id, topic_id} = useParams();
    const [message, setMessage] = useState  (null);
    const [currentTopic, setCurrentTopic] = useState({});
    const [result, setResult]= useState([]);
    const location = useLocation();
    const current_display = location.pathname.split("/")[2];
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);
    const index = topic_id;

    useEffect(() => {
        const fetchTopic = async () => {
            const res = await JsonHandler(`message/graph/${machine_id}/${index}`, "POST");
            if (res.success) {
                const parsedData = Object.entries(res.message);
                const tempPointsData = {};
                parsedData.forEach((topic) => {
                    const graphDatas  = JSON.parse(topic[1]).data;
                    graphDatas.forEach(point => {
                        // set X axis if not set new x
                        const tempName = tempPointsData[point.x] || { name: point.x };
                        tempName[topic[0]] = point.y;
                        tempPointsData[point.x] = tempName;
                    });
                });
                const results = Object.values(tempPointsData);
                //results seems good
                setResult(results);
                setMessage(res.message);
                setCurrentTopic(res.topic);
                setBgData(res.background);
                setLoading(false);
            }
        }
        
        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }
        if(!message) fetchTopic();
        const intervalId = setInterval(fetchTopic, 10000);
        const intervalDatas = setInterval(dataLoaded, 15000);
        return () => clearInterval(intervalId, intervalDatas);

    }, [ machine_id, setCurrentTopic, setResult, setMessage, index, setLoading, setDataEmpty, setBgData, message ]);

    /* 
    TODO     need to render a data that looks like this : 
    TODO     [{
    TODO        x: 'some date aka xVal',
    TODO        line1: yVal,
    TODO        line2: yVal,
    TODO        lineX: yVal,
    TODO     },]
    */

    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

    if (loading) {
        return(
            <section className="display__container tile__background">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }

    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }

    return (
        <>
            {!rightDisplay ? (
                <ErrorRoute />
            ) : (
                <section 
                className="display__container tile__background"
                style={displayTileStyle(bgData, currentTopic.topic_opacity)}
                >
                {currentTopic && (
                <>
                    <h2 className="display__title">{currentTopic.topic_title}</h2>
                    {message && result && (
                    <Chart realData={result}></Chart>
                    )}
                </>
                )}
        </section>
        )}
        </>
    );};

export default HistoryCurves;