import {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import JsonHandler from "../service/JsonHandler";
import Chart from "./Chart";
import ErrorRoute from "../components/route/ErrorRoute";
import { Oval } from "react-loader-spinner";

const Curves = () => {
    let {machine_id, topic_id} = useParams();
    const [currentTopic, setCurrentTopic] = useState({});
    const [message, setMessage] = useState(null);
    const [result, setResult]= useState([]);
    const location = useLocation();
    const current_display = location.pathname.split("/")[2];
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);

    const index = topic_id;

    const getCurrentDate = () => {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = String(currentDate.getMonth() + 1).padStart(2, '0');
        let day = String(currentDate.getDate()).padStart(2, '0');
        let hours = String(currentDate.getHours()).padStart(2, '0');
        let minutes = String(currentDate.getMinutes()).padStart(2, '0');
        let seconds = String(currentDate.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {
        let currentTimer = 3000;
        const fetchTopic = async () => {
            const res = await JsonHandler(`message/graph/${machine_id}/${index}`, "POST");
            if (res.success) {
                const mess = res.message;
                //* get each entries as a data ( each entries = each Area)
                const parsedData = Object.entries(mess);
                const tempPointsData = {};

                parsedData.forEach((point) => {
                        const [topic, pointVal] = point;
                        
                        //old data treatment → to change later no ? 
                        const tempName = tempPointsData[getCurrentDate()] || { name: getCurrentDate() };
                        tempPointsData["name"] = tempName.name;
                        tempPointsData[topic] = pointVal;
                });

                setResult(prevResult => {
                    if (prevResult.length >= 90) {
                        return [...prevResult.slice(1), tempPointsData];
                    } else {
                        return [...prevResult, tempPointsData];
                    }
                });
                setMessage(mess);
                setCurrentTopic(res.topic);
                setBgData(res.background);
                setLoading(false);
                if(res.topic && res.topic.topic_params.timer && res.topic.topic_params.timer>3){
                    currentTimer = `${res.topic.topic_params.timer}000`;
                }
            }
        }

        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }

        fetchTopic();
        const intervalId = setInterval(fetchTopic, currentTimer);
        const intervalDatas = setInterval(dataLoaded, 15000);
        return () => clearInterval(intervalId, intervalDatas);
    }, [machine_id, setCurrentTopic, setResult, setMessage, setLoading, setDataEmpty, index, setBgData]);

    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

    if (loading) {
        return(
            <section className="display__container tile__background">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }

    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }

    return (
        <>
            {!rightDisplay ? (
                <ErrorRoute />
            ) : (
                <section 
                className="display__container tile__background"
                style={displayTileStyle(bgData, currentTopic.topic_opacity)}
                >
                {currentTopic && (
                <>
                    <h2 className="display__title">{currentTopic.topic_title}</h2>
                    {message && result && (
                        <Chart realData={result}></Chart>
                    )}
                </>
                )}
        </section>
            )}
            </>
        );};

export default Curves;